const router = {
  path: '/videoEcosystem',
  meta: {
    title: '视频测验（生态系统）',
    icon: 'video-camera',
    show: true,
  },
  component: () => import('@/views/transition.vue'),
  children: [{
    meta: {},
    path: '',
    props: { category: '2' },
    component: () => import('@/views/videoWatch/index.vue'),
  }],
};

export default router;
