const router = {
  path: '/guide',
  meta: {
    title: '视频测验指导',
    icon: 'edit-outline',
    show: true,
  },
  component: () => import('@/views/transition.vue'),
  children: [{
    meta: {},
    path: '',
    props: { category: '2' },
    component: () => import('@/views/guide/index.vue'),
  }],
};

export default router;
