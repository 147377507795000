const router = {
  path: '/questionnaireManagement',
  meta: {
    title: '问卷管理',
    icon: 'tickets',
    authority: true,
    show: true,
  },
  component: () => import('@/views/transition.vue'),
  children: [{
    meta: {
      authority: true,
    },
    path: '',
    component: () => import('@/views/questionnaireManagement/index.vue'),
  }],
  hidden: true,
};

export default router;
