
const router = {
  path: '/login',
  meta: {
    isLogin: true,
  },
  component: () => import('@/views/auth/login.vue'),
};

export default router;
