import Vue from 'vue';
import VueRouter from 'vue-router';
import WelcomeRouter from '@/router/welcome';
import guideRouter from '@/router/guide';
import VideoPhotosynthesisRouter from '@/router/videoPhotosynthesis';
import QuestionnairePhotosynthesisRouter from '@/router/questionnairePhotosynthesis';
import VideoEcosystemRouter from '@/router/videoEcosystem';
import QuestionnaireEcosystemRouter from '@/router/questionnaireEcosystem';
import CommentManagementRouter from '@/router/commentManagement';
import QuestionnaireManagementRouter from '@/router/questionnaireManagement';
import SurveyManagementRouter from '@/router/surveyManagement';
import UserManagementRouter from '@/router/userManagement';
import AuthorityManagementRouter from '@/router/authorityManagement';
import AuthRouter from '@/router/auth';
import ExceptionRouter from '@/router/exception';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('@/layout/index.vue'),
    redirect: '/welcome',
    children: [
      WelcomeRouter,
      guideRouter,
      VideoPhotosynthesisRouter,
      QuestionnairePhotosynthesisRouter,
      VideoEcosystemRouter,
      QuestionnaireEcosystemRouter,
      CommentManagementRouter,
      QuestionnaireManagementRouter,
      SurveyManagementRouter,
      UserManagementRouter,
      AuthorityManagementRouter,
    ],
  },
  AuthRouter,
  ExceptionRouter,
  {
    path: '*', redirect: '/404',
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, form, next) => {
  const userInfo = localStorage.getItem('userInfo');
  const { isLogin, authority } = to.meta;
  if (!userInfo && !isLogin) {
    next('/login');
  } else if (userInfo) {
    const { roleId } = JSON.parse(userInfo);
    if (isLogin) next(false);
    else if (!authority || roleId === 1) next();
    else next('/404');
  } else next();
});

export default router;
