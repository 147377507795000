const router = {
  path: '/welcome',
  meta: {
    title: '欢迎页',
    icon: 'magic-stick',
  },
  component: () => import('@/views/transition.vue'),
  children: [{
    meta: {},
    path: '',
    component: () => import('@/views/welcome/index.vue'),
  }],
};

export default router;
