const router = {
  path: '/questionnaireEcosystem',
  meta: {
    title: '纸笔测验（生态系统）',
    icon: 'edit-outline',
    show: true,
  },
  component: () => import('@/views/transition.vue'),
  children: [{
    meta: {},
    path: '',
    props: { category: '2' },
    component: () => import('@/views/questionnaire/index.vue'),
  }],
};

export default router;
